<template>
    
    <router-view />
</template>

<script>


export default {
  components: {
    
  }
}
</script>

<style>

</style>

